import React from "react";
import Helmet from "react-helmet";
import { ListGroup } from "react-bootstrap";
import { Jumbotron } from "../components/Jumbotron";
import SEO from "../components/SEO";
import "../assets/css/App.css";
import "../assets/css/index.css";

import Layout from '../components/Layout'

export default () => (
  <Layout>
    <SEO
      title="Renewable Energy Biomass Boiler"
      description="Have issues with your Biomass boiler? Contact us to find our more information and set up a visit with one of our engineers"
      keywords="Biomass Boiler, Biomass Boiler london, Biomass Boiler potters bar, Biomass Boiler central london, Biomass Boiler hertfordshire"
    />
    <Jumbotron imgClassName="img6"/>
    <div class="white-segment text-left">
      <h1 class="heading-color">Biomass boilers</h1>
      <p class="">If your biomass boiler is correctly sized and installed in a well-insulated property, with a good local sustainable fuel source, it could be a great long-term investment. 
      </p>
      <h3 class="pb-2">Main benefits of biomass boilers</h3>
      <ListGroup variant="flush">
        <ListGroup.Item>Produces much lower carbon emissions than coal gas and oil</ListGroup.Item>
        <ListGroup.Item>Runs on wood pellets, logs or woodchips</ListGroup.Item>
        <ListGroup.Item>Can be installed in commercial or domestic properties</ListGroup.Item>
        <ListGroup.Item>No fuel wastage</ListGroup.Item>
        <ListGroup.Item>Part of the government’s renewable heat incentive scheme (RHI)</ListGroup.Item>
      </ListGroup>
    </div>
  </Layout>
);
